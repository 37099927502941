/* eslint-disable */
import { App } from "vue";
import { FormInstance } from "element-plus";
import { Traveller } from "@/core/types";
import pd from "@/core/services/PersonalDetails";
import { store } from "@/store";
import { focus, insuredFamilyValue, insuredParentValue } from "@/store/stateless"
import router from "@/router";
import Validations from "@/core/validations";

export const personalDetails = (app: App<Element>) => {
    const dates = app.config.globalProperties.dates;
    app.config.globalProperties.personalDetails = {
        async click(formEl: FormInstance) {
            if (!formEl) return;
            if(store.state.personals.customer.travellers_info.length == 0) {
                window.localStorage.setItem('travellerLength','1');
            }
            formEl.validate(async (valid, fields: any) => {
                if (valid) {
                    window.localStorage.removeItem('thankyou')
                    
                    const customer = store.state.personals.customer
                    const recent_searches = store.state.personals.recent_searches
                    pd.setLoading(true);
                    if(customer.travellers_info.length == 0) {
                        focus.personal = ''
                        formEl.scrollToField('200')
                        focus.member = ''
                        window.localStorage.setItem('travellerLength','1');
                    } else {
                        // remove empty Member Form
                        let newMembers = customer.travellers_info.filter(x => {
                            if(x.id == null)
                            {
                                if( x.dob != null )
                                {
                                    return x;
                                }
                            }
                            else{
                                if( x.dob != null ) {
                                    return x;
                                } 
                            }
                        })
                        if(customer.travellers_info.length != newMembers.length)
                        {
                            focus.personal = ''
                            formEl.scrollToField('200')
                            focus.member = ''
                            window.localStorage.setItem('travellerLength','2');
                        }
                        else{
                            window.localStorage.removeItem('travellerLength');
                            window.localStorage.removeItem('callBack');
                            customer.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : '';
                            customer.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : '';
                            customer.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : '';
                            customer.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : '';
                            // customer.insurance_type = router.currentRoute.value.query?.insurance_type ? parseInt(router.currentRoute.value.query.insurance_type.toString()) : 1;
                            // const sessionId = window.localStorage.getItem('session_id');
                            const sessionId = router.currentRoute.value.query?.session_id ?  router.currentRoute.value.query.session_id.toString() : '';
                            const agent_id = router.currentRoute.value.query?.agent_id ?  router.currentRoute.value.query.agent_id.toString() : '';
             
                            if(sessionId)
                                customer.session_id = sessionId;
                            if(agent_id)
                                customer.agent_id = parseInt(agent_id);
                            
                            pd.setCustomer(customer)
                            const payload = {
                                customer:customer
                            }
                            const result = await pd.post(payload);
                            if (result.status !== 200 && result.response) {
                                const { email_taken, phone_taken } = result.response.data.data
                                Validations.email_taken = email_taken
                                Validations.phone_taken = phone_taken
                                formEl.validate(async (valid, field: any) => {
                                    focus.personal = ''
                                    formEl.scrollToField(Object.keys(field)[0])
                                    focus.personal = Object.keys(field)[0]
                                    focus.member = ''
                                })
                            }
                        }
        
                    }
                    pd.setLoading(false);
                } else {
                    focus.personal = ''
                    formEl.scrollToField(Object.keys(fields)[0])
                    focus.personal = Object.keys(fields)[0]
                    focus.member = ''
                }
            })
        },

        reset(formEl: FormInstance | undefined) {
            if (!formEl) return
            formEl.clearValidate()
        },

        deleteMember(members: Array<Traveller>, index: number) {
            const customer = store.state.personals.customer
            if(members[index].id != null) {
                customer.deleted_members.push(members[index])
            }
            members.splice(index, 1);
            if(members.length == 1) {
                customer.product_type = 1;
            }
        },

        editMember(members: Array<Traveller>, member: Traveller) {
            members.map(x => {
                x.is_editable = false;
            });

            member.is_editable = true;

            members.map(x => {
                if (!x.first_name || x.dob === 'Invalid date' || !x.gender_id || !x.nationality_id) {
                    this.deleteMember(members, members.length - 1);
                }
            })
        }
    }
}